import React from "react";
import { post, random, sortFunction } from "./cupla";
import { Cupla3D, Dot, Obe } from "./cupla3D";

const RADIUS = 300;
const LIMIT_Z = -600;

export default class App extends React.Component<{}> {
    input: HTMLInputElement;
    canvas: HTMLCanvasElement;
    dots: Obe[] = [];
    handler = new Handler();

    constructor(props: {}) {
        super(props);
        window.app = this;
    }

    render() {
        return (
            <div
                className="FlexColumn AppScroll"
                style={{
                    width: "100%",
                    height: "100%",
                    background: "radial-gradient(#156815, var(--dark-green))",
                    alignItems: "center",
                    overflowY: "auto",
                    justifyContent: "center"
                }}
            >
                <canvas
                    ref={e => (this.canvas = e)}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        width: "100%",
                        height: "100%"
                    }}
                />

                <div
                    style={{
                        flex: "1 0 30%"
                    }}
                />

                <div>
                    <input
                        ref={e => (this.input = e)}
                        type="text"
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="off"
                        className="form-control"
                        style={{
                            width: "300px",
                            maxWidth: "95%",
                            border: "2px solid #050",
                            borderRadius: "18px"
                        }}
                        onKeyUp={(ev: any) =>
                            this.onKey(ev.which || ev.keyCode)
                        }
                    />
                </div>

                <div
                    style={{
                        paddingTop: "40px",
                        textAlign: "center",
                        flex: "1 1 60%",
                        width: "40%"
                        //aspectRatio: "1"
                    }}
                >
                    {this.handler.renderLinks()}
                </div>
            </div>
        );
    }

    componentDidMount(): void {
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        Cupla3D.init(this.canvas);

        // World system
        let system = new Obe();

        var DOTS = 1000;
        for (var i = 0; i < DOTS; i++) {
            var dot = new Dot(1, "rgba(255,255,255,0.5)");
            dot.setSpeed(0, 0.1, 0);
            this.resetDot(dot, true);
            this.dots.push(dot);
            system.addObe(dot);
        }

        Cupla3D.start(this.timer);

        this.input.focus();
    }

    resetDot(dot: Obe, initial: boolean) {
        let x = random(-RADIUS, +RADIUS);
        let y;
        if (initial) {
            y = random(-RADIUS, +RADIUS);
        } else {
            y = -RADIUS;
        }
        let z = random(LIMIT_Z, 0);
        dot.moveTo(x, y, z);
    }

    timer = () => {
        // Reset dots which go outside visible range
        for (var dot of this.dots) {
            if (dot.y > this.canvas.height / 2) {
                this.resetDot(dot, false);
            }
        }
    };

    openPath(path: string, newTab?: boolean) {
        if (newTab) {
            // NOTE: must post for the new tab to get focus
            post(() => window.open(path));
        } else {
            (window as Window).location = path;
        }
    }

    onKey(key: number) {
        if (key === 13) {
            this.onEnter();
        } else if (key === 27) {
            this.input.value = "";
        }
    }

    onEnter() {
        let str = this.input.value.trim();
        str = this.handler.input(str);
        this.openPath(str);
    }
}

type Link = {
    keywords: string[];
    name: string;
    url: string;
};

class Handler {
    links: Link[] = [
        { keywords: [""], name: "", url: "" },
        { keywords: ["anne"], name: "", url: "cupla.net/email/anne.html" },
        { keywords: ["areena"], name: "Yle Areena", url: "areena.yle.fi" },
        {
            keywords: ["cuplasoftware"],
            name: "Cupla Software",
            url: "cuplasoftware.com"
        },
        { keywords: ["disney"], name: "Disney+", url: "disneyplus.com" },
        { keywords: ["facebook"], name: "Facebook", url: "facebook.com" },
        {
            keywords: ["gmail", "mail"],
            name: "Google Mail",
            url: "mail.google.com"
        },
        { keywords: ["imdb"], name: "IMDb", url: "imdb.com" },
        { keywords: ["linkedin"], name: "LinkedIn", url: "linkedin.com" },
        { keywords: ["maps"], name: "Google Maps", url: "google.com/maps" },
        { keywords: ["netflix"], name: "Netflix", url: "netflix.com" },
        { keywords: ["op"], name: "OP", url: "op.fi" },
        { keywords: ["prime"], name: "Prime Video", url: "primevideo.com" },
        { keywords: ["twitter"], name: "Twitter", url: "twitter.com" },
        { keywords: ["veikkaus"], name: "Veikkaus", url: "veikkaus.fi" },
        { keywords: ["viaplay"], name: "Viaplay", url: "viaplay.fi" },
        { keywords: ["wilma"], name: "Wilma", url: "kuopio.inschool.fi" },
        { keywords: ["wikipedia"], name: "Wikipedia", url: "en.wikipedia.org" },
        { keywords: ["youtube"], name: "YouTube", url: "youtube.com" },
        { keywords: ["appletv"], name: "AppleTV", url: "tv.apple.com" },
        { keywords: [], name: "Movie Trailers", url: "apple.com/trailers" }
    ];

    input(str: string): string {
        // Keyword
        let s = str.toLowerCase();
        for (let link of this.links) {
            if (link.keywords.includes(s)) {
                return "https://" + link.url;
            }
        }

        // .com/.fi => open as-is
        let i = str.lastIndexOf(".");
        if (i > 0) {
            let j = str.length - i - 1;
            if (j >= 2 && j <= 3) {
                return "https://" + str;
            }
        }

        // Google search
        return "https://google.com/search?q=" + encodeURIComponent(str);
    }

    renderLinks() {
        return this.links
            .filter(link => link.name)
            .sort(sortFunction("name"))
            .map(link => (
                <a
                    key={link.name}
                    href={"https://" + link.url}
                    style={{
                        display: "inline-block",
                        color: "white",
                        margin: "0 5px"
                    }}
                >
                    {link.name}
                </a>
            ));
    }
}
