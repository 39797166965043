/** Cupla 3D Library - copyright (c) Teemu Lätti 2012-2018 */

export class Cupla3D {
    static canvas: HTMLCanvasElement;
    static context: CanvasRenderingContext2D;
    static camera: Camera;
    static obes: Obe[] = [];
    static planes: Plane[] = [];
    static opaque: number = 1;

    /** Initialize 3D library */
    static init(canvas: HTMLCanvasElement) {
        // Canvas
        this.canvas = canvas;
        this.context = canvas.getContext("2d");
        if (!this.context) {
            return false;
        }

        // Static transformation so that y goes up and origin is in the middle of canvas
        this.context.setTransform(
            1,
            0,
            0,
            -1,
            this.context.canvas.width / 2,
            this.context.canvas.height / 2
        );

        this.camera = new Camera();

        return true;
    }

    /** Starts 3D timer */
    static start(preCallbackFunc?: () => void, postCallbackFunc?: () => void) {
        this.do3DTimerFunc(preCallbackFunc, postCallbackFunc);
    }

    static do3DTimerFunc(
        preCallbackFunc: () => void,
        postCallbackFunc: () => void
    ) {
        setTimeout(
            () =>
                window.requestAnimationFrame(() =>
                    this.do3DTimerFunc(preCallbackFunc, postCallbackFunc)
                ),
            30
        );

        // User callback (pre)
        if (preCallbackFunc) {
            preCallbackFunc();
        }

        // Timer tick for all objects
        for (let obe of Cupla3D.obes) {
            obe.timer();
        }

        // Draw 3D world
        this.draw();

        // User callback (post)
        if (postCallbackFunc) {
            postCallbackFunc();
        }
    }

    /** Clears canvas */
    static clear() {
        this.context.save();
        this.context.setTransform(1, 0, 0, 1, 0, 0);
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.context.restore();
    }

    /** Draws 3D world */
    static draw() {
        // Clear projections from all points
        for (let obe of this.obes) {
            for (let pnt of obe.pnts) {
                pnt.clearProjection();
            }
        }

        // Collect planes from all objects
        this.planes = [];
        for (let obe of this.obes) {
            for (let plane of obe.planes) {
                let add = false;
                if (plane.facing && plane.pnts.length >= 3) {
                    // Check that facing front
                    plane.pnts[0].project();
                    plane.pnts[1].project();
                    plane.pnts[2].project();
                    let v1 = new Vector(
                        plane.pnts[0].pnt2D,
                        plane.pnts[1].pnt2D
                    );
                    let v2 = new Vector(
                        plane.pnts[1].pnt2D,
                        plane.pnts[2].pnt2D
                    );
                    let a1 = v1.angle();
                    let a2 = v2.angle();
                    a2 -= a1;
                    if ((a2 > -Math.PI && a2 < 0) || a2 > Math.PI) {
                        add = true;
                    }
                } else {
                    // Facing not required => add always
                    add = true;
                }

                if (add) {
                    this.planes.push(plane);
                }
            }
        }

        // Pre-calculate plane centers for sorting
        for (let plane of this.planes) {
            plane.z_ = plane.center().z;
        }

        // Sort planes to z-order, back first
        this.planes.sort((a, b) => b.z_ - a.z_);

        // Make sure all points have been projected (not only the ones that were needed above),
        // and check all planes that they can be drawn
        let planes2 = this.planes;
        this.planes = [];
        for (let plane of planes2) {
            let ok = true;
            for (let pnt of plane.pnts) {
                if (!pnt.project()) {
                    ok = false; // projection failed => forget plane
                    break;
                }
            }
            if (ok) {
                this.planes.push(plane);
            }
        }

        // Clear (just before drawing instead of before calculations)
        this.clear();

        // Draw all planes, back first
        for (let plane of this.planes) {
            plane.draw(this.context);
        }

        // Done drawing, so clear calculated planes
        this.planes = [];
    }
}

export class Pnt {
    x: number;
    y: number;
    z: number;

    constructor(x: number, y: number, z: number) {
        this.x = x;
        this.y = y;
        this.z = z;
    }

    /** Moves point to specified position */
    moveTo(x: number, y: number, z: number) {
        this.x = x;
        this.y = y;
        this.z = z;
    }

    /** Moves point relative to current position */
    move(dx: number, dy: number, dz: number) {
        this.x += dx;
        this.y += dy;
        this.z += dz;
    }

    /** Scales point position by multi */
    scale(mul: number) {
        this.x *= mul;
        this.y *= mul;
        this.z *= mul;
    }

    /** Turns point relative to origin (degrees) */
    turn(ax: number, ay: number, az: number) {
        let arx = deg2rad(ax);
        let ary = deg2rad(ay);
        let arz = deg2rad(az);

        // Turn x
        if (arx !== 0) {
            let y = this.y * Math.cos(arx) - this.z * Math.sin(arx);
            let z = this.y * Math.sin(arx) + this.z * Math.cos(arx);
            this.y = y;
            this.z = z;
        }

        // Turn y
        if (ary !== 0) {
            let z = this.z * Math.cos(ary) - this.x * Math.sin(ary);
            let x = this.z * Math.sin(ary) + this.x * Math.cos(ary);
            this.z = z;
            this.x = x;
        }

        // Turn z
        if (arz !== 0) {
            let x = this.x * Math.cos(arz) - this.y * Math.sin(arz);
            let y = this.x * Math.sin(arz) + this.y * Math.cos(arz);
            this.x = x;
            this.y = y;
        }
    }

    length() {
        return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.z, 2)); //!!!! y not counted
    }
}

export class Vector {
    x1: number;
    y1: number;
    x2: number;
    y2: number;

    constructor(a1: Vector);
    constructor(a1: Pnt, a2: Pnt);
    constructor(a1: number, a2: number, a3: number, a4: number);
    constructor(a1: any, a2?: any, a3?: any, a4?: any) {
        if (a1 instanceof Vector) {
            // Vector(rhs)
            this.x1 = a1.x1;
            this.y1 = a1.y1;
            this.x2 = a1.x2;
            this.y2 = a1.y2;
        } else if (a1 instanceof Pnt) {
            // Vector(p1,p2)
            this.x1 = a1.x;
            this.y1 = a1.y;
            this.x2 = a2.x;
            this.y2 = a2.y;
        } else {
            // Vector(x1,y1,x2,y2)
            this.x1 = a1;
            this.y1 = a2;
            this.x2 = a3;
            this.y2 = a4;
        }
    }

    dx() {
        return this.x2 - this.x1;
    }

    dy() {
        return this.y1 - this.y2;
    }

    angle() {
        let a = Math.atan2(this.dy(), this.dx());
        if (a < 0) {
            a += 2 * Math.PI;
        }
        return a;
    }

    length() {
        return Math.sqrt(Math.pow(this.dx(), 2) + Math.pow(this.dy(), 2));
    }

    setAngle(ang: number) {
        let len = this.length();
        this.x2 = this.x1 + len * Math.cos(ang);
        this.y2 = this.y1 - len * Math.sin(ang);
    }

    rotate(ang: number) {
        let a = this.angle();
        a += ang;
        let len = this.length();
        this.x2 = this.x1 + len * Math.cos(a);
        this.y2 = this.y1 - len * Math.sin(a);
    }

    setLength(len: number) {
        // Set new length by adjusting end
        let a = this.angle();
        this.x2 = this.x1 + len * Math.cos(a);
        this.y2 = this.y1 - len * Math.sin(a);
    }

    distance(pnt: Pnt) {
        // Get distance of pnt from vector as vector from this vector to pnt (angle is 90 degress from this vector),
        // returns null if not next to this vector
        let v = new Vector(this.x1, this.y1, pnt.x, pnt.y); // vector from my beg to point
        let angle = this.angle() - v.angle(); // angle between this and previous
        if (angle < 0) angle += 2 * Math.PI;
        let len = v.length() * Math.cos(angle); // "left" length of triangle, which travels along this
        if (len < 0 || len > this.length())
            // if not inside me, then the point is not next to us
            return null;
        let dist = new Vector(this); // copy this vector
        dist.setLength(len); // shorten to the length
        return new Vector(dist.x2, dist.y2, pnt.x, pnt.y); // return vector from that point to the point
    }
}

export class ObePnt extends Pnt {
    parent: Obe; // Parent object
    pnt2D: Pnt; // Projected 2D point (+z) as absolute point

    constructor(x: number, y: number, z: number, parent: Obe) {
        super(x, y, z); // Point relative to parent
        this.parent = parent;
    }

    /** Gets absolute point (not relative to parent). Returns Pnt */
    getAbsolutePnt() {
        let pnt3d = new Pnt(this.x, this.y, this.z);
        if (this.parent) {
            let parentpnt = this.parent.getAbsolutePnt();
            pnt3d.x += parentpnt.x;
            pnt3d.y += parentpnt.y;
            pnt3d.z += parentpnt.z;
        }
        return pnt3d;
    }

    /** Projects point to 2D (if not projected yet) */
    project(force?: boolean) {
        if (!this.pnt2D || force) {
            //! calculates invalid twice?
            // Project point from 3D to 2D

            // Real absolute point (3D)
            let pnt3d = this.getAbsolutePnt();

            // Take camera position into account BEFORE projection
            // so perspective depends how the point is in relation to camera
            pnt3d.x -= Cupla3D.camera.x;
            pnt3d.y -= Cupla3D.camera.y;
            pnt3d.z -= Cupla3D.camera.z;

            // Projection by distance to 2D (simplified)
            //! old: let z = 1 + (pnt3d.z / 600);
            let div = pnt3d.z / 600; //! magic number?
            if (div !== 0) {
                this.pnt2D = new Pnt(pnt3d.x / div, pnt3d.y / div, div);
            } else {
                this.pnt2D = new Pnt(pnt3d.x, pnt3d.y, div);
            }

            // This is fake tilting //!?
            this.pnt2D.y += Cupla3D.camera.y;
        }
        return this.pnt2D.z > 0;
    }

    /** Clears projection */
    clearProjection() {
        this.pnt2D = null;
    }

    /** Rotates point == does nothing (overridden in derived classes) */
    rotate(ax: number, ay: number, az: number) {}
}

export class Obe extends ObePnt {
    pnts: ObePnt[] = []; // [ObePnt] Object points (relative to object origin)
    planes: Plane[] = []; // [Plane] Object planes (made out of object points)
    speed3D: Pnt; // [Pnt] Speed vector
    rotation3D: ObePnt; // [ObePnt] Rotation angles
    turn3D: Pnt; // [Pnt] Turn angles

    constructor() {
        super(0, 0, 0, null); // ObePnt as object origin relative to parent object

        // Automatically add this object to 3D objects
        Cupla3D.obes.push(this);
    }

    /** Adds new point position to this object */
    addPnt(x: number, y: number, z: number) {
        let pnt = new ObePnt(x, y, z, this);
        this.pnts.push(pnt);
        return pnt;
    }

    /** Adds object as our child */
    addObe(obe: Obe) {
        obe.parent = this; // objects parent is this
        this.pnts.push(obe); // add as a point, so it will be transformed as any other point
    }

    /** Adds plane to object */
    addPlane(plane: Plane) {
        this.planes.push(plane);
    }

    /** Determines object center (absolute) */
    center() {
        let arr = [];
        for (let pnt of this.pnts) {
            arr.push(pnt.getAbsolutePnt());
        }
        return centerFromArray(arr);
    }

    /** Scales object == scales points by multi */
    scale(mul: number) {
        for (let pnt of this.pnts) {
            pnt.scale(mul);
        }
    }

    /** Rotates object == turns+rotates points (they can be child-objects) */
    rotate(ax: number, ay: number, az: number) {
        for (let pnt of this.pnts) {
            pnt.turn(ax, ay, az);
            pnt.rotate(ax, ay, az);
        }
    }

    /** Sets constant speed */
    setSpeed(x: number, y: number, z: number) {
        this.speed3D = new Pnt(x, y, z);
    }

    /** Sets constant turn (rotation around origin) */
    setTurn(tx: number, ty: number, tz: number) {
        this.turn3D = new Pnt(tx, ty, tz);
    }

    /** Sets constant rotation (around itself) */
    setRotate(ax: number, ay: number, az: number) {
        this.rotation3D = new ObePnt(ax, ay, az, this); //!?
    }

    /** Timer tick */
    timer() {
        // Apply speed
        if (this.speed3D) {
            this.move(this.speed3D.x, this.speed3D.y, this.speed3D.z);
        }

        // Apply turn
        if (this.turn3D) {
            this.turn(this.turn3D.x, this.turn3D.y, this.turn3D.z);
        }

        // Apply rotation
        if (this.rotation3D) {
            this.rotate(
                this.rotation3D.x,
                this.rotation3D.y,
                this.rotation3D.z
            );
        }
    }
}

export class Dot extends Obe {
    size: number;
    fillStyle: string;

    constructor(size: number, fillStyle: string) {
        super();

        this.size = size ? size : 2; // size of dot
        this.fillStyle = fillStyle; // Fill style (color) or null==gray

        // Add plane that has the object point as the only point
        let plane = new Plane();
        plane.addPnt(this);
        plane.facing = false;
        this.addPlane(plane);

        // Override plane drawing
        plane.draw = (ctx: CanvasRenderingContext2D) => {
            let p = this.pnt2D;
            ctx.beginPath();
            if (this.fillStyle) {
                ctx.fillStyle = this.fillStyle;
            } else {
                ctx.fillStyle = "rgba(170,170,170," + Cupla3D.opaque + ")"; // #aa == 170
            }
            ctx.strokeStyle = ctx.fillStyle;
            let size = this.size;
            let div = plane.pnts[0].pnt2D.z * 1.5; //! to method
            size /= div;
            ctx.arc(p.x, p.y, size, 0, 2 * Math.PI);
            ctx.fill();
        };
    }
}

export class Cube extends Obe {
    constructor(
        size: number,
        fillStyle: string,
        lineStyle: string,
        lineWidth: number
    ) {
        super();

        // Points
        let p0 = this.addPnt(-1, -1, -1); // left  - top    - front
        let p1 = this.addPnt(+1, -1, -1); // right - top    - front
        let p2 = this.addPnt(+1, +1, -1); // right - bottom - front
        let p3 = this.addPnt(-1, +1, -1); // left  - bottom - front
        let p4 = this.addPnt(-1, -1, +1); // left  - top    - back
        let p5 = this.addPnt(+1, -1, +1); // right - top    - back
        let p6 = this.addPnt(+1, +1, +1); // right - bottom - back
        let p7 = this.addPnt(-1, +1, +1); // left  - bottom - back

        // Planes
        let plane = null;

        // Front
        plane = new Plane(fillStyle, lineStyle, lineWidth);
        plane.addPnt(p0);
        plane.addPnt(p1);
        plane.addPnt(p2);
        plane.addPnt(p3);
        this.addPlane(plane);

        // Back
        plane = new Plane(fillStyle, lineStyle, lineWidth);
        plane.addPnt(p4);
        plane.addPnt(p7);
        plane.addPnt(p6);
        plane.addPnt(p5);
        this.addPlane(plane);

        // Bottom
        plane = new Plane(fillStyle, lineStyle, lineWidth);
        plane.addPnt(p3);
        plane.addPnt(p2);
        plane.addPnt(p6);
        plane.addPnt(p7);
        this.addPlane(plane);

        // Top
        plane = new Plane(fillStyle, lineStyle, lineWidth);
        plane.addPnt(p0);
        plane.addPnt(p4);
        plane.addPnt(p5);
        plane.addPnt(p1);
        this.addPlane(plane);

        // Left
        plane = new Plane(fillStyle, lineStyle, lineWidth);
        plane.addPnt(p0);
        plane.addPnt(p3);
        plane.addPnt(p7);
        plane.addPnt(p4);
        this.addPlane(plane);

        // Right
        plane = new Plane(fillStyle, lineStyle, lineWidth);
        plane.addPnt(p1);
        plane.addPnt(p5);
        plane.addPnt(p6);
        plane.addPnt(p2);
        this.addPlane(plane);

        // Final object size
        this.scale(size);
    }
}

export class Curve extends Obe {
    constructor(a1: Pnt, a2: Pnt, a3: Pnt) {
        super();

        let p1 = this.addPnt(a1.x, a1.y, a1.z);
        let p2 = this.addPnt(a2.x, a2.y, a2.z); //! this point should be modified to be double
        let mul = 1.3; //!?
        p2.scale(mul);
        let p3 = this.addPnt(a3.x, a3.y, a3.z);

        let plane = new Plane();
        plane.facing = false; //! stupid
        plane.addPnt(p1);
        plane.addPnt(p2);
        plane.addPnt(p3);

        plane.draw = function (ctx) {
            ctx.strokeStyle = "rgba(210,210,210," + Cupla3D.opaque + ")";
            ctx.beginPath();
            ctx.moveTo(p1.pnt2D.x, p1.pnt2D.y);
            ctx.quadraticCurveTo(
                p2.pnt2D.x,
                p2.pnt2D.y,
                p3.pnt2D.x,
                p3.pnt2D.y
            );
            ctx.stroke();
        };

        this.addPlane(plane);
    }
}

export class Plane {
    pnts: ObePnt[] = []; // [ObePnt] Plane points in clock-wise order, relative to parent
    facing: boolean = true; // If true, plane facing will be checked before drawing
    fill: boolean = true; // Fill?
    fillStyle: string;
    lineStyle: string;
    lineWidth: number;
    z_: number;

    constructor(fillStyle?: string, lineStyle?: string, lineWidth?: number) {
        this.fillStyle = fillStyle; // Fill style (color) or null==white
        this.lineStyle = lineStyle; // Line style (color) or null=darkGray
        this.lineWidth = lineWidth; // Line width or null==3
    }

    /** Plane draw method that should be overridden or use this default */
    draw(ctx: CanvasRenderingContext2D) {
        // Fill
        if (this.fill) {
            if (this.fillStyle) {
                ctx.fillStyle = this.fillStyle;
            } else {
                ctx.fillStyle = "rgba(255,255,255," + Cupla3D.opaque + ")"; // #ff == 255
            }
            ctx.beginPath();
            let pnt = this.pnts[this.pnts.length - 1].pnt2D;
            ctx.moveTo(pnt.x, pnt.y);
            for (let k in this.pnts) {
                pnt = this.pnts[k].pnt2D;
                ctx.lineTo(pnt.x, pnt.y);
            }
            ctx.fill();
        }

        // Lines
        let lineWidth;
        if (this.lineWidth) {
            lineWidth = this.lineWidth;
        } else {
            lineWidth = 3;
        }
        let div = this.pnts[0].pnt2D.z * 1.5; //! to method
        if (div > 1) {
            lineWidth /= div;
        }
        ctx.lineWidth = lineWidth;
        if (this.lineStyle) {
            ctx.strokeStyle = this.lineStyle;
        } else {
            ctx.strokeStyle = "rgba(170,170,170," + Cupla3D.opaque + ")"; // #aa == 170
        }
        /*let prev = this.pnts[this.pnts.length - 1].pnt2D;
        for (let k in this.pnts) {
            let pnt = this.pnts[k].pnt2D;
            Cupla3D.context.Line(prev.x, prev.y, pnt.x, pnt.y);
            prev = pnt;
        }*/
    }

    /** Determines plane center (absolute) */
    center() {
        if (this.pnts.length === 1) {
            // Only one point => center it is
            return this.pnts[0].getAbsolutePnt();
        } else {
            let arr = [];
            for (let i in this.pnts) {
                arr.push(this.pnts[i].getAbsolutePnt());
            }
            return centerFromArray(arr);
        }
    }

    /** Adds point to plane */
    addPnt(pnt: ObePnt) {
        this.pnts.push(pnt);
    }

    /** Moves plane == moves points */
    move(dx: number, dy: number, dz: number) {
        for (let i in this.pnts) {
            this.pnts[i].move(dx, dy, dz);
        }
    }

    /** Scales plane == scales points */
    scale(mul: number) {
        for (let i in this.pnts) {
            this.pnts[i].scale(mul);
        }
    }

    /** Rotates plane == turns points */
    rotate(ax: number, ay: number, az: number) {
        for (let i in this.pnts) {
            this.pnts[i].turn(ax, ay, az);
        }
    }
}

/** class Camera extends ObePnt */
export class Camera extends ObePnt {
    constructor() {
        super(0, 0, -600, null); // ObePnt as camera position
    }
}

const deg2radvalue = (2 * Math.PI) / 360;

function deg2rad(deg: number) {
    return deg * deg2radvalue;
}

function centerFromArray<T>(arr: T[]): T {
    return arr[Math.floor(arr.length / 2)];
}
